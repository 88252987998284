<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-btn text class="mt-3" @click="goToShop"><v-icon>mdi-chevron-left</v-icon>Zurück zum
                    Shop</v-btn>
            </v-col>
            <v-col cols="12">
                <h1>Meine Reservierungen</h1>
                <div v-if="myOrders.length > 0">
                    <p>
                        Vielen Dank für deine {{ myOrders.length > 1 ? 'Reservierungen' : 'Reservierung' }}! Deine Reservierung ist vorläufig registriert, aber noch nicht abgeschlossen. Wir melden uns schnellstmöglich bei dir, sobald sie bestätigt wurde. Danach kannst du das Fahrrad entweder abholen oder dir liefern lassen.

                    </p>
                </div>
                <v-alert class="mt-5" v-else type="info" color="blue" outlined>
                    Du hast derzeit keine Reservierung. <br />
                </v-alert>
            </v-col>
        </v-row>
        <v-row v-if="myOrders.length > 0">
            <v-col cols="12" sm="6" md="4" v-for="order in myOrders" :key="order.bikeId">
                <v-card>

                    <v-img contain :src="`/api/bikes/image/${order.bike.brandName}/${order.bike.imageFileName}`"
                        alt="Bike Image">
                    </v-img>
                    <v-row>
                        <v-col align="center" cols="12">
                            <v-chip class="white--text mt-5" :color="statusMapColor[order.orders[0].status]">Status: {{
                                statusMap[order.orders[0].status] }}</v-chip>
                        </v-col>
                    </v-row>
                    <v-card-title>{{ order.description }}</v-card-title>
                    <v-card-subtitle>
                        <p>Menge: {{ order.orders[0].amount }}</p>
                        <p>Datum: {{ $moment(order.orders[0].date).format("DD.MM.YY HH:mm") }} Uhr</p>
                        <v-btn block class="mx-auto" text small @click="showMoreInfoToggle(order)">
                            <span v-if="!order.showMoreInfo">Mehr Infos</span>
                            <span v-else>Weniger Infos</span>
                        </v-btn>


                        <div v-if="order.showMoreInfo">
                            <p>Marke: {{ order.bike.brandName }}</p>
                            <p>Geeignete Körpergrösse: {{ order.bike.suitableHeight }}</p>
                            <p>Grösse: {{ order.bike.size }}</p>
                        </div>
                    </v-card-subtitle>


                    <v-card-actions class="d-flex justify-center " v-if="order.orders[0].status === 'open'">
                        <v-btn class="mb-5" color="error" @click="cancelOrder(order.orders[0]._id)">
                            Stornieren
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog max-width="500px" v-model="showCancelDialog">
            <v-card>
                <v-card-title class="primary white--text">Stornierung der Reservation</v-card-title>
                <v-card-text class="mt-5">
                    Um die Bestellung zu stornieren, nimm bitte Kontakt mit uns auf
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn text @click="showCancelDialog = false">Zurück</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import api from '@/api';

export default {
    data() {
        return {
            showCancelDialog: false,
            myOrders: [],
            statusMapColor: {
                'open': "orange",
                'confirmed': "primary",
                'canceled': "red",
            },
            statusMap: {
                'open': "offen",
                'confirmed': "bestätigt",
                'canceled': "abgebrochen",
            }
        };
    },
    methods: {
        showMoreInfoToggle(order) {
            order.showMoreInfo = !order.showMoreInfo;
            this.$forceUpdate();
        },
        async fetchMyOrders() {
            try {
                const response = await api.fetchMyOrders();
                this.myOrders = response.data;
            } catch (error) {
                this.$toast.error('Fehler beim Laden der Bestellungen.');
            }
        },
        async cancelOrder(orderId) {
            try {
                await api.cancelOrder(orderId);
                this.$toast.success('Bestellung erfolgreich storniert.');
                this.$emit('refetchBikes')
                this.fetchMyOrders();
            } catch (error) {
                this.$toast.error('Fehler beim Stornieren der Bestellung.');
            }
        },
        goToShop() {
            this.$router.push({ name: 'BikeShop' });
        }
    },
    created() {
        this.fetchMyOrders();
    }
};
</script>
